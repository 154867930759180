<template>
  <!-- <div class="login"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  <div class="login-box">
    <div class="title">欢迎注册在线备课系统</div>
    <div class="success">
      <img src="../../img/登录_slices/成功.png" alt="" />
      <div class="text">您已成功注册账号，请前往登录页面</div>
    </div>
    <div class="login-btn" @click="toLogin">
      <img src="../../img/登录_slices/矩形 2 拷贝 2.png" alt="" />

      <span>前往登录</span>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import $ from "jquery";

export default {
  name: "Login",
  data() {
    return {
      checked: false,
    };
  },
  mounted() {},
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
  },
  components: {
    // HelloWorld,
  },
};
</script>
<style lang="less" scoped>
.login-box {
  width: 630/96in;
  height: 738/96in;
  background: url("../../img/登录_slices/组3.png") no-repeat;
  position: fixed;
  top: 50%;
  transform: translateY(-49%);
  right: 111px;
  text-align: center;
  padding: 108/96in 90/96in 80/96in;
  .title {
    font-size: 30/96in;
    font-weight: bold;
    color: #121212;
    height: 30/96in;
    line-height: 30/96in;
    letter-spacing: 0.1em;
    margin-bottom: 110/96in;
  }
  .success {
    margin-bottom: 100/96in;
    .text {
      font-size: 18/96in;
      font-weight: 400;
      color: #313339;
      line-height: 63/96in;
    }
  }
  // 登录
  .login-btn {
    position: relative;
    height: 116/96in;
    margin-bottom: 3/96in;
    cursor: pointer;
    img {
      position: absolute;
      left: -29/96in;
    }
    span {
      position: absolute;
      color: #edeef5;
      font-size: 20/96in;
      left: 50%;
      top: 36/96in;
      transform: translateX(-50%);
    }
  }
}
</style>
